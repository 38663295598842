@media (min-width: 280px) and (max-width: 300px) {
  #chat-container {
    margin-bottom: 22px;
  }

  #content-div{
    margin-bottom: 97px;
  }

  .videoFrame {
    width: 258px;
    height: 147px;
  }
}

@media (min-width: 370px) and (max-width: 380px) {
  .videoFrame {
    width: 270px;
    height: 154px;
  }
}

@media (min-width: 350px) and (max-width: 365px) {
  .videoFrame {
    width: 272px;
    height: 155px;
  }
}
@media (min-height: 750px) and (max-height: 760px) {
  #chat-container {
    margin-bottom: 82px;
  }
}

@media (min-height: 600px) and (max-height: 605px) {
  #chat-container {
    margin-bottom: 18vh;
  }
}
@media (min-height: 661px) and (max-height: 881px) {
  #chat-container {
    margin-bottom: 10vh;
  }
}

@media (min-height: 580px) and (max-height: 5px) {
  #chat-container {
    margin-bottom: 10vh;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #chat-container {
    margin-bottom: 5vh;
  }
}

.scrollbar-side::-webkit-scrollbar {
  width: 8px;
}
.scrollbar-side::-webkit-scrollbar-track {
  background: #6f7c97;
  border-radius: 10px;
}
.scrollbar-side {
  z-index: 9999;
}
.scrollbar-side::-webkit-scrollbar-thumb {
  background: #1d2535;
  border-radius: 10px;
}
.company-website-btn a:hover {
  border-radius: 8px;
  border: 1px solid #5761fe;
  background: #5761fe;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
}
.company-website-btn a svg {
  margin-left: 5px;
}
.company-website-btn a {
  border-radius: 8px;
  padding-right: 30px;
  width: 100%;
  border: 1px solid #004eeb;
  background: #004eeb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: 600;
  line-height: 20px;
  height: 40px;
  line-height: 42px;
  padding-top: 2px;
  padding-left: 30px;
}

.loader-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: #0003;
  width: 100%;
  height: 100%;
}    


.spinLoder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}