.DateContainer {
  display: flex;
  align-items: center;
  width: 130px;
  height: 40px;
  border: 1px solid #ece9f5;
  border-radius: 5px;
  color: "#050C46";
  margin-left: 5px;
}

.dateInput {
  width: 100%;
  height: 100%;
}

.dateInput input {
  width: calc(100% + 25px);
  margin: 10px 0px 0px 4px;
  border: none;
  background: transparent;
  color: "#050C46";
  z-index: 1;
}

.icon {
  margin: 0px 10px 4px 7px;
  height: 20px;
  width: 20px;
  color: "#050C46";
}

.selected_day {
  color: #fff;
  background-color: #00aa38;
  border-radius: 20px;
}

.react-datepicker__header {
  background: white;
  border-bottom: none;
  color: "#050C46";
}

textarea:focus,
input:focus {
  outline: none;
}

.senderText {
  background-color: white;
  color: #050c46;
  font-size: 16px;
  font-weight: 700;
  border-radius: 12px;
}

.senderInfo {
  color: #050c46;
  font-size: 22px;
  font-weight: 700;
  margin: 15px 4px;
}

.campaignBox {
  border: 1px solid #ece9f5;
  background-color: white;
  border-radius: 12px;
  padding: 15px;
}

.subHeader {
  border: 1px solid #ece9f5;
  background-color: #ece9f5;
  border-radius: 12px;
  padding: 10px;
}

.contentBorder {
  border: 1px solid #ece9f5;
  background-color: #ece9f5;
  padding: 10px;
  border-radius: 12px;
}

.currentButton {
  color: white;
  background-color: #5761fe;
  border-radius: 8px;
}
.currentButton:disabled {
  background-color: #ccc9d4;
}

.BackButton {
  border: 1px solid #ded0eb;
  color: #050c46;
}

.apexcharts-tooltip {
  background: #050c46;
  color: #fefefe;
}

.apexcharts-tooltip-title {
  background-color: #050c46;
  color: #fefefe;
  border-color: #050c46;
}

.apexcharts-toolbar-download-icon {
  padding-right: 80px;
}
