@media screen and (max-width: 700px) {
    .loginContainer {
    padding: 69px;
    }
  }

  @media screen and (max-width:700px) {
    .imageContainer {
    padding: 9px;
    }
  }

