.basicDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #050c46;
}

.subHeadding {
  color: #050c46;
  font-size: 16px;
}

.title {
  color: #050c46;
  font-size: 14px;
}

.subTitle {
  font-weight: 500;
  font-size: 14px;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dropZoneArea {
  width: 100%;
  height: 128px;
  border: 1px dashed #b3b5c7;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.imageLoader {
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  background: #fff;
  opacity: 70%;
  border-radius: 12px;
}

.navigation {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: max-content;
  display: block;
  margin-left: 50px;
  margin-right: 50px;
  z-index: 2;
  position: sticky;
  transform: translateX(-50%);
  top: 15%;
  display: flex;
  border-radius: 3rem;
}

.activeMenuList {
  background-color: rgb(220 221 239);
  margin: "20px";
}

.unactiveMenuList {
  background-color: transparent;
  margin-bottom: "20px";
  margin-top: "20px";
}

.listLink {
  color: #050c46;
  font-weight: 500;
  border-radius: 8px;
  font-size: 14px;
  text-decoration: none;
  margin: 10px;
  padding: 5px 20px 5px 10px;
  border-radius: 6px;
  color: #050c46;
}

.uploadButton {
  color: #5761fe;
  font-size: 14px;
  border: none;
  background-color: transparent;
  font-weight: 700;
}

.dragText {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: #55597a;
  font-weight: 400;
}

.svgText {
  font-size: 14px;
  margin-top: 0px;
  color: #55597a;
  font-weight: 400;
}

.uploadIcon {
  margin-top: 5px;
  height: 45px;
  width: 45px;
}

@media screen and (max-width: 600px) {
  .mainContainer {
    flex-direction: column;
    /* margin-right: 5px; */
  }
}
